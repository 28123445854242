import * as React from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';
import { useEffect } from 'react';
import { __ } from 'react-i18n';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { rem } from 'polished';
import styled from '../../theme/styled-components';
import {
  AddToCartModal,
  FlexCol,
  FlexRow,
  FlexRowCenter,
  FlexRowCenterVertical,
  prop,
  ZemplinTitle,
} from 'eshop-defaults';
import { connect } from 'react-redux';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import { connectSsr } from 'ssr-service';
import { withRouter } from 'react-router';
import API from '../../services/API';
import { langSelector } from '../App/selectors';
import { Button } from 'eshop-defaults/lib/components/Zemplin/General/Button';
import { Loader } from 'eshop-defaults/lib/components/Zemplin/MyAccount/RequestDetail';
import {
  addItemToCartFromModal,
  addToCartModalProductSelector,
  addToCartModalVisibleSelector,
  setAddToCartModalVisibility,
} from '../Cart/cartSlice';
import './Scanner.css';

const qrcodeRegionId = 'html5qr-code-full-region';

// Creates the configuration object for Html5QrcodeScanner.
const createConfig = props => {
  let config: any = {};
  if (props.fps) {
    config.fps = props.fps;
  }
  if (props.qrbox) {
    config.qrbox = props.qrbox;
  }
  if (props.aspectRatio) {
    config.aspectRatio = props.aspectRatio;
  }
  if (props.disableFlip !== undefined) {
    config.disableFlip = props.disableFlip;
  }
  config.formatsToSupport = [5];
  return config;
};

const Scan = props => {
  const { dispatch, lang, user, addToCartModalProduct, addToCartModal } = props;
  const [inputValue, setInputValue] = React.useState('');
  const [decodedText, setDecodedText] = React.useState('');
  const [decodedResult, setDecodedResult] = React.useState('');
  const [foundProducts, setFoundProducts] = React.useState([]);
  const [searchingTerm, setSearchingTerm] = React.useState('');
  const [isSearching, setIsSearching] = React.useState(false);
  const [scanner, setScanner] = React.useState<any>(null);
  let searchProductsTimeout: any = React.useRef<any>(null);
  const isCorrectUser = user && user.b2b && user.id === 5829;
  const [functionCalled, setFunctionCalled] = React.useState(0);
  const userHeliosId = prop(user, 'outer_id');
  // console.log({ user, addToCartModalProduct, addToCartModal, isSearching });
  useEffect(() => {
    setTimeout(() => {
      if (scanner) {
        if (addToCartModalProduct) {
          scanner.pause();
        } else {
          setTimeout(() => {
            scanner.resume();
          }, 400);
        }
      }
    }, 50);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToCartModalProduct]);

  const qrCodeSuccessCallback = (decodedText, decodedResult) => {
    if (!decodedText) {
      return;
    }
    if (searchProductsTimeout) {
      clearTimeout(searchProductsTimeout);
    }

    searchProductsTimeout = setTimeout(() => {
      if (isSearching || addToCartModalProduct) {
        return;
      }
      try {
        if (scanner) {
          scanner.pause();
        }
      } catch (e) {
        //
      }
      // handle decoded results here
      setDecodedResult(decodedResult);
      setDecodedText(decodedText);

      if (decodedText) {
        try {
          if (scanner) {
            scanner.pause();
          }
        } catch (e) {
          //
        }
        const searchText = decodedText.includes(`${userHeliosId}-`)
          ? decodedText.trim().replace(`${userHeliosId}-`, '')
          : decodedText;
        searchForProduct(searchText);
      }

      // if (searchProductsTimeout && searchProductsTimeout.current) {
      //   clearTimeout(searchProductsTimeout.current);
      // }
      // searchProductsTimeout.current = setTimeout(() => {
      // }, 400);
    }, 500);
  };

  const searchForProduct = async (term?: string): Promise<void> => {
    setFunctionCalled(a => a + 1);
    if (isSearching || addToCartModalProduct) {
      return;
    }

    if (!term) {
      term = inputValue;
    }

    if ((term === searchingTerm && addToCartModalProduct) || !term) {
      return;
    }
    setSearchingTerm(term);
    setIsSearching(true);

    const { products } = (await API.searchElasticProducts({
      limit: 2,
      q: term,
      langId: lang,
      isQuickBuy: 1,
    })) as any;

    setIsSearching(false);
    if (!addToCartModalProduct && prop(products, 'products.length')) {
      try {
        if (scanner) {
          scanner.pause();
        }
      } catch (e) {
        //
      }
      dispatch(
        setAddToCartModalVisibility(
          false,
          { ...prop(products, 'products.0'), isQuickBuy: true },
          true,
          true,
        ),
      );
      setFoundProducts(products.products);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const qrCodeElementRequestPermissions = document.getElementById(
        'html5-qrcode-button-camera-permission',
      );
      if (qrCodeElementRequestPermissions) {
        qrCodeElementRequestPermissions.innerHTML = 'Povoliť kameru';
      }
    }, 50);
  }, []);

  useEffect(() => {
    setInterval(() => {
      const qrCodeElementRequestPermissions = document.getElementById(
        'html5-qrcode-button-camera-start',
      );
      if (
        qrCodeElementRequestPermissions &&
        qrCodeElementRequestPermissions.innerHTML !== 'Skenovať'
      ) {
        qrCodeElementRequestPermissions.innerHTML = 'Skenovať';
      }
    }, 1000);
  }, []);

  useEffect(() => {
    setInterval(() => {
      const qrCodeElementRequestPermissions = document.getElementById(
        'html5-qrcode-button-camera-stop',
      );
      if (
        qrCodeElementRequestPermissions &&
        qrCodeElementRequestPermissions.innerHTML !== 'Stop'
      ) {
        qrCodeElementRequestPermissions.innerHTML = 'Stop';
      }
    }, 1000);
  }, []);

  useEffect(() => {
    dispatch(
      setBreadCrumbPath(BreadCrumbType.CUSTOM, {
        name: __('Rýchlý nákup'),
        path: '/rychly-nakup',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isCorrectUser) {
      return;
    }
    // when component mounts
    const config = createConfig(props);
    const verbose = props.verbose === true;
    // Suceess callback is required.
    if (!qrCodeSuccessCallback) {
      return;
      // throw 'qrCodeSuccessCallback is required callback.';
    }
    const html5QrcodeScanner = new Html5QrcodeScanner(
      qrcodeRegionId,
      config,
      verbose,
    );
    html5QrcodeScanner.render(qrCodeSuccessCallback, props.qrCodeErrorCallback);
    setScanner(html5QrcodeScanner);
    // cleanup function when component will unmount
    return () => {
      html5QrcodeScanner.clear().catch(error => {
        console.error('Failed to clear html5QrcodeScanner. ', error);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isCorrectUser) {
    return null;
  }

  return (
    <>
      <Wrapper className="container container--wide">
        <MetaTags
          canonicalUrl={`${process.env.REACT_APP_BASE_URL}/rychly-nakup`}
          tags={{ title: __('Rýchlý nákup') }}
        />
        <FlexCol>
          {/* <div>called: {functionCalled}</div> */}
          {/* <div>{`has added product: ${!!addToCartModalProduct}`}</div>
          <div>{`aaaa: ${addToCartModal}`}</div> */}
          <StyledZemplinTitle>{__('Rýchlý nákup')}</StyledZemplinTitle>
          {isSearching && (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          )}
          {(true || !addToCartModalProduct) && (
            <div
              id={qrcodeRegionId}
              // style={{ display: addToCartModalProduct ? 'none' : '' }}
            />
          )}
          {/* <FlexRowCenterVertical style={{ margin: '8px 0' }}>
            <span>Rozoznaný text:</span>{' '}
            <strong>{JSON.stringify(decodedText)}</strong>
          </FlexRowCenterVertical> */}
          {/* <br />2 - {JSON.stringify(decodedResult)} */}
          <br />
          <FlexRowCenterVertical>
            <Input
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              name={'search'}
              placeholder={__('Zadajte kód produktu')}
              invalid={false}
            />
            <SearchButton onClick={() => searchForProduct()}>
              {isSearching ? <Loader /> : __('Potvrdiť')}
            </SearchButton>
          </FlexRowCenterVertical>
        </FlexCol>
      </Wrapper>
    </>
  );
};
const LoaderWrapper = styled.div`
  margin-bottom: ${rem(16)};
`;

export const StyledZemplinTitle = styled(ZemplinTitle)`
  margin: 0 0 ${rem(32)};
  font-size: ${rem(30)};
  line-height: ${rem(38)};
  margin-bottom: ${rem(32)};

  ${({ theme }) => theme.mediab.l925`
    margin: ${rem(32)} 0 margin-bottom: ${rem(16)};
    font-size: ${rem(24)};
    line-height:${rem(32)};
  `}
`;

export const SecondaryZemplinTitle = styled(ZemplinTitle)`
  font-size: ${rem(24)};
  line-height: ${rem(32)};
  margin-bottom: ${rem(32)};

  ${({ theme }) => theme.mediab.l925`
    margin: ${rem(12)} 0 0;
    font-size: ${rem(20)};
    line-height:${rem(28)};
  `}
`;

const SearchButton = styled(Button)`
  transition: filter 0.2s ease;

  &:hover {
    filter: brightness(90%);
  }
`;

const Input = styled.input<{ invalid: boolean }>`
  width: 100%;
  height: ${rem(48)};
  border: ${rem(1)} solid ${({ theme, invalid }) =>
  invalid ? theme.color.danger : theme.color.gray86}
  border-radius: ${rem(4)};
  padding-left: ${rem(15)};
  font-size: ${rem(14)};
  font-family: ${({ theme }) => theme.font.primary};
  color: ${({ theme }) => theme.color.text};
  box-shadow: ${({ invalid }) =>
    invalid ? `0 0 ${rem(8)} rgba(255, 0, 0, 0.5)` : 'none'};
    
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px` : '')};
  
  ${({ marginBottom }) =>
    marginBottom ? `margin-bottom: ${marginBottom}px` : ''};

  &::placeholder {
    font-size: ${rem(14)}; 
    color: ${({ theme }) => theme.color.textSecondary};       
  }

  &:focus {
    outline: none;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Wrapper = styled(FlexRow)`
  width: 100%;
  padding-top: ${rem(56)};
  margin-bottom: ${rem(80)} !important;

  // @media only screen and (max-width: 1740px) {
  //   max-width: ${rem(1080)};
  //   padding-top: ${rem(32)};
  //   margin-bottom: ${rem(32)} !important;
  // }
  //
  // @media only screen and (max-width: 1300px) {
  //   max-width: ${rem(960)};
  //   padding-top: ${rem(32)};
  //   margin-bottom: ${rem(32)} !important;
  // }

  ${({ theme }) => theme.mediab.l1050`
      padding-top: ${rem(32)};
      margin-bottom: ${rem(32)} !important;
  `}
`;

const mapStateToProps = state => {
  return {
    lang: langSelector(state),
    user: state.auth.user,
    addToCartModalProduct: addToCartModalProductSelector(state),
    addToCartModal: addToCartModalVisibleSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Scan' })(withRouter(Scan)),
);
